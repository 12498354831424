<template>
  <div class="o-model-career-job">
    <ODate v-if="!hideDate" class="job-date font-dimmed" :date="date" />
    <OValue :label="`${prefix}.title-label`" variant="line">{{ title }}</OValue>
    <OValue v-if="hasPractice" :label="`${prefix}.practice-label`" variant="rows">
      <OList :items="practice" />
    </OValue>
  </div>
</template>

<script>
import OValue from '../../value';
import OList from '../../list';
import ODate from 'library/components/src/components/so/date';

const prefix = 'o.model-career-job';

export default {
  name: 'o-model-career-job',
  components: { OList, ODate, OValue },
  props: {
    date: Object,
    title: String,
    practice: Array,
    hideDate: Boolean,
  },
  computed: {
    prefix: () => prefix,
    hasPractice() {
      const { practice } = this;
      return Array.isArray(practice) && practice.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../component';

.job-date {
  font-size: 0.8rem;
  margin-bottom: $space-small;
  text-decoration: underline;
}
</style>
