<template>
  <div class="o-profile-header" :class="{ clickable: isEditableAllowed }" @click="onClick">
    <OEdit v-if="isEditableAllowed && onlyEditButton" />
    <BRow v-else>
      <BCol>
        <h2 class="headline">
          <template v-if="headlineKey">
            {{ $t(headlineKey) }}
          </template>
          <template v-else>
            <slot />
          </template>
        </h2>
      </BCol>
      <BCol v-if="isEditableAllowed && sectionRoute" cols="auto">
        <OEdit />
      </BCol>
    </BRow>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue';
import { profileUpload } from '@/src/sitemap';
import SOIcon from 'library/components/src/components/so/icon';
import OEdit from '../edit';

export default {
  name: 'o-profile-header',
  components: { BRow, BCol, OEdit, SOIcon },
  props: {
    headlineKey: String,
    section: String,
    id: {
      type: String,
      require: true,
    },
    onlyEditButton: Boolean,
    isEditableAllowed: Boolean,
  },
  computed: {
    sectionRoute() {
      const { section, id } = this;
      if (profileUpload.hasOwnProperty(section)) {
        let route = { ...profileUpload[section].route };
        route.params = { id };
        return route;
      }
      return null;
    },
  },
  methods: {
    onClick() {
      if (!this.isEditableAllowed) {
        return;
      }

      const sectionRoute = this.sectionRoute;
      if (sectionRoute) {
        this.$router.push(sectionRoute);
      }
    },
  },
};
</script>
