<template>
  <!-- all detail pages -->
  <div class="print-document">
    <div class="profile-detail" :class="{ 'for-screen-only': !printDetails }">
      <!-- basic information -->
      <div class="print-page" data-section="basic">
        <h1 class="page-headline">{{ pageTitle }} <OProfileName v-bind="profile.person" /></h1>
        <div class="persona-split-container">
          <div class="childable persona-image">
            <OProfileHeader
              class="is-not-printable positionable-top-right-corner"
              section="image"
              only-edit-button
              :is-editable-allowed="allowEditable"
              :id="id"
            />
            <OModelImage class="mb-2" :picture="profile.education.picture" />
          </div>
          <div class="persona-details">
            <OProfileHeader
              class="as-top-aligned"
              section="personal"
              :id="id"
              :is-editable-allowed="allowEditable"
              :headline-key="`${prefix}.personal-headline`"
            />
            <OValue class="is-not-printable" variant="column" label="fields.profile-id" :value="profile.internalId" />
            <OValue variant="column" class="person-name" :label="`${prefix}.fields.personal.name`">
              <OProfileName v-bind="profile.person" />
            </OValue>
            <OValues :values="personalValues" />
          </div>
        </div>

        <!-- position -->
        <div class="print-section">
          <hr />
          <OProfileHeader
            section="workspace"
            :id="id"
            :is-editable-allowed="allowEditable"
            :headline-key="`${prefix}.position-headline`"
          />
          <OValues :values="positionValues" />
        </div>

        <!-- salary -->
        <div class="print-section">
          <hr />
          <OProfileHeader
            section="workspace"
            :id="id"
            :is-editable-allowed="allowEditable"
            :headline-key="`${prefix}.salary-headline`"
          />
          <OValues :values="salaryValues" />
        </div>

        <!-- workspace/workplace -->
        <div class="print-section" v-if="profile.workspace">
          <hr />
          <OProfileHeader
            section="workspace"
            :id="id"
            :is-editable-allowed="allowEditable"
            :headline-key="`${prefix}.workspace-headline`"
          />
          <OModelWorkplace v-bind="profile.workspace.workplace" />
        </div>

        <!-- driver licence -->
        <div class="print-section" v-if="profile.workspace">
          <hr />
          <OProfileHeader
            section="personal"
            :id="id"
            :is-editable-allowed="allowEditable"
            :headline-key="`${prefix}.license-headline`"
          />
          <OValue :label="`profile.driver-license-label`" variant="column">
            {{ resolveProfileOptionValue('personal.driverLicence') }}
          </OValue>
          <OValue
            v-if="getModelValue('personal.driverLicence')"
            :label="`profile.passenger-car-label`"
            variant="column"
          >
            {{ resolveProfileOptionValue('personal.passengerCar') }}
          </OValue>
        </div>

        <!-- assessment -->
        <div class="print-section">
          <hr />
          <OProfileHeader
            section="assessment"
            :id="id"
            :is-editable-allowed="allowEditable"
            :headline-key="`${prefix}.assessment-headline`"
          />
          <OValues :values="assessmentValues" variant="rows" />
        </div>

        <!-- language -->
        <div class="print-section">
          <hr />
          <OProfileHeader
            section="education"
            :id="id"
            :is-editable-allowed="allowEditable"
            :headline-key="`${prefix}.language-headline`"
          />
          <OValues :values="languageValues" />
        </div>
      </div>

      <!-- educations and career -->
      <div
        v-if="hasGroups(['education.schools', 'education.studies', 'career.furtherEducation'])"
        class="print-page print-section"
        data-section="schools"
      >
        <h1 v-if="showPageHeadline" class="page-headline">{{ $t(`${prefix}.headline`) }}</h1>
        <div v-if="showGroup('education.schools')">
          <OProfileHeader
            section="schools"
            :id="id"
            :is-editable-allowed="allowEditable"
            :headline-key="`${prefix}.education-headline`"
          />

          <SCEach :component-name="OModelCareerSchool" :items="profile.education.schools" item-key="schools" />
        </div>

        <div v-if="showGroup('education.studies')" class="print-section">
          <OProfileHeader
            section="studies"
            :id="id"
            :is-editable-allowed="allowEditable"
            :headline-key="`${prefix}.studies-headline`"
          />
          <SCEach :component-name="OModelCareerStudy" :items="profile.education.studies" item-key="studies" />
        </div>

        <div v-if="showGroup('career.furtherEducation')" class="print-section">
          <OProfileHeader
            section="career"
            :id="id"
            :is-editable-allowed="allowEditable"
            :headline-key="`${prefix}.further-education-headline`"
          />
          <SCEach
            :component-name="OModelCareerEducation"
            :items="profile.career.furtherEducation"
            item-key="furtherEducation"
          />
        </div>
      </div>

      <!-- professional career -->
      <div class="print-page" data-section="professional">
        <h1 v-if="showPageHeadline" class="page-headline">{{ $t(`${prefix}.headline`) }}</h1>

        <!-- professional-career -->
        <div class="print-section">
          <OProfileHeader
            section="career"
            :id="id"
            :is-editable-allowed="allowEditable"
            :headline-key="`${prefix}.professional-career-headline`"
          />

          <SCEach
            :component-name="OModelCareerProfessional"
            :items="profile.career.professionalCareer"
            item-key="professionalCareer"
          />
        </div>

        <!-- documents -->
        <div v-if="showGroup('education.documents')" class="print-section">
          <OProfileHeader
            section="documents"
            :id="id"
            :is-editable-allowed="allowEditable"
            :headline-key="`${prefix}.documents-headline`"
          />
          <template v-if="isValid(profile.education.documents)">
            <div v-for="(document, index) in profile.education.documents" :key="`document-${index}`" class="document">
              <OThumbnail class="document-thumbnail for-screen-only" :document="document.file" />
              <ODownload class="document-label" :label="document.name" v-bind="document.file" />
            </div>
          </template>
          <div v-else>
            {{ $t(`${prefix}.documents-empty-label`) }}
          </div>
        </div>
      </div>

      <!-- provision -->
      <div class="print-page" data-section="provision">
        <h1 v-if="showPageHeadline" class="page-headline">{{ $t(`${prefix}.headline`) }}</h1>

        <h2 class="page-subline">
          {{ $t(`${prefix}.provision-headline`) }}
        </h2>
        <div class="single-value">
          {{ $t(`${prefix}.provision-label`, { commission }) }}
        </div>

        <h2 class="page-subline">
          {{ $t(`${prefix}.interval-headline`) }}
        </h2>
        <OList :items="intervals" />

        <!-- allowed companies -->
        <template v-if="allowForbittenCompanies">
          <h2 class="page-subline is-not-printable">
            {{ $t(`${prefix}.forbitten-companies-headline`) }}
          </h2>
          <ul class="bullet-list is-not-printable">
            <li v-for="name in forbiddenCompanies" :key="name">
              {{ name }}
            </li>
            <li v-if="!forbiddenCompanies.length">
              {{ $t(`${prefix}.no-data-label`) }}
            </li>
          </ul>
        </template>
      </div>
    </div>

    <!-- contact page -->
    <div class="print-page" :class="{ 'for-screen-only': !printContact }" data-section="contact">
      <div class="print-section">
        <h1 class="page-headline">
          {{ $t(`${prefix}.contact-headline`) }}
        </h1>
        <OProfileHeader section="personal" :id="id" :is-editable-allowed="allowEditable">
          <OProfileName v-bind="profile.person" />
        </OProfileHeader>
        <OValues :values="contactValues" />
      </div>
    </div>
  </div>
</template>

<script>
import { available } from 'library/components/src/tools/filters/available';
import types from 'library/src/utilities/types';
import { asOptions } from 'library/src/utilities/as-options';
import get from 'lodash.get';
import { ASSESSMENT_LIST } from 'library/src/models/assessment.enum';
import { delay } from 'library/src/utilities/delay';
import { resolveOption } from 'library/src/utilities/resolve-option';

import OValue from '../../o/value';
import OValues from '../../o/values';
import OProfileName from '../../o/profile/name';
import OList from '../../o/list';
import OProfileToolbarPreview from '../../o/profile/toolbar/preview';
import OModelImage from '../../o/model/image';
import OModelPosition from '../../o/model/position';
import OModelCareerSchool from '../../o/model/career/school';
import OModelCareerStudy from '../../o/model/career/study';
import OModelCareerEducation from '../../o/model/career/education';
import OModelCareerProfessional from '../../o/model/career/professional';
import OModelWorkplace from '../../o/model/workplace';
import ODownload from '../../o/download';
import OThumbnail from '../../o/thumbnail';
import OProfileHeader from '../../o/profile/header';
import SCEach from 'library/components/src/components/sc/each';

const prefix = 'vw.profile-detail';

const SECTION_DETAIL = 'detail';
const SECTION_CONTACT = 'contact';
const SECTION_DOCUMENTS = 'documents';

export default {
  components: {
    SCEach,
    OModelImage,
    OModelCareerSchool,
    OModelCareerEducation,
    OModelCareerProfessional,
    OModelCareerStudy,
    OModelWorkplace,
    OProfileHeader,
    OThumbnail,
    ODownload,
    OProfileToolbarPreview,
    OList,
    OValue,
    OValues,
    OProfileName,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    layout: {
      type: String,
      default: 'page',
    },
    allowEditable: Boolean,
    allowForbittenCompanies: Boolean,
  },
  data() {
    return {
      printContact: true,
      printDetails: true,
    };
  },
  computed: {
    prefix: () => prefix,
    showPageHeadline() {
      return this.layout === 'page';
    },

    id() {
      return this.$router.currentRoute.params.id;
    },

// pass components
    OModelCareerEducation: () => OModelCareerEducation,
    OModelCareerProfessional: () => OModelCareerProfessional,
    OModelCareerStudy: () => OModelCareerStudy,
    OModelCareerSchool: () => OModelCareerSchool,

    pageTitle() {
      const person = get(this, 'profile.person');
      const headline = this.$t(`${prefix}.headline`);
      return `${headline}` + (person ? `: ` : '');
    },

    personalValues() {
      return this.getValueSet(this.profile.personal, 'personal', [
        { selector: 'dateOfBirth', transform: 'date' },
        'birthPlace',
        { selector: 'workPermit', translationKey: 'generic' },
        { selector: 'nationality', translationKey: 'generic.language' },
        {
          selector: 'appliedDate',
          transform: 'date',
          condition: () => this.profile.personal.workPermit === 'requested',
        },
        { selector: 'maritalStatus', translationKey: 'generic.marital-status' },
        { selector: 'children', translationKey: 'generic.children' },
        {
          selector: 'assistanceSecured',
          condition: () => this.getModelValue('personal.children') > 0,
        },
      ]);
    },
    positionValues() {
      const position = get(this, 'profile.workspace.position');
      return this.getValueSet(position, 'position', [
        {
          selector: 'positions',
          renderer(h, item) {
            const props = { positions: item.value };
            return h(OModelPosition, { props });
          },
        },
        'keywords',
        {
          selector: 'experience',
          condition: item => item.value > 0, // hide if not set or valid
          renderer: (h, item) => this.$t('generic.in-years', item), // translate
        },
      ]);
    },
    salaryValues() {
      const workspace = get(this, 'profile.workspace');
      const invalidTranslation = 'generic.not-set';
      const template = `fields.salary-value`;

      return this.getValueSet(workspace, 'salary', [
        {
          selector: 'availability',
          transform: v => available(v, this.$t('generic.available.past')),
        },
        {
          selector: 'currentSalary',
          transform: 'money',
          template,
          invalidTranslation,
        },
        {
          selector: 'salaryRequest',
          transform: 'money',
          template,
          invalidTranslation,
        },
        {
          selector: 'desiredSalary',
          transform: 'money',
          template,
          invalidTranslation,
        },
        {
          selector: 'salaryNote',
        },
        {
          selector: 'desired',
          translationKey: 'generic.desired',
        },
      ]);
    },
    assessmentValues() {
      return this.getValueSet(
        this.profile.assessment,
        'assessment',
        ASSESSMENT_LIST.map(assesment => {
          return {
            selector: assesment,
            labelKey: `generic.assessment.${assesment.toLowerCase()}`,
          };
        }),
      );
    },
    languageValues() {
      return this.getValueSet(this.profile.education, 'language', [
        {
          selector: 'nativeLanguage',
          translationKey: 'generic.language',
        },
        {
          selector: 'languages',
          props: {
            class: 'as-languages',
          },
          renderer: (h, item) => {
            const languages = item.value;

            if (Array.isArray(languages) && languages.length > 0) {
              return Object.keys(languages).map(key => {
                const { language, level } = languages[key];
                const languageLabel = this.$t(`generic.language.${language}`);
                const levelLabel = this.$t(`generic.language-level.${level}`);
                return h('div', { class: 'language-item' }, `${languageLabel} (${levelLabel})`);
              });
            }

            return h('div', '-');
          },
        },
      ]);
    },
    contactValues() {
      const person = this.getValueSet(this.profile.person, 'contact', [
        { selector: 'title', translationKey: 'generic.title' },
        { selector: 'salutation', translationKey: 'generic.salutations' },
        'firstname',
        'lastname',
      ]);

      const address = this.getValueSet(this.profile.address, 'contact', ['street', 'zip', 'locality', 'country']);

      const communication = this.getValueSet(this.profile.person, 'contact', ['phone', 'mobile', 'email']);
      return [...person, ...address, ...communication];
    },
    forbiddenCompanies() {
      return get(this, 'profile.workspace.forbiddenCompanies', []);
    },
    commission() {
      return get(this, 'profile.provision.commission', {});
    },
    intervals() {
      const intervals = get(this, 'profile.provision.intervals');
      return Array.from(intervals).map(interval => this.$t(`intervals.${interval}`));
    },
  },

  // methods
  methods: {
    isValid(value) {
      const type = typeof value;

      if (value) {
        if (type === 'string') {
          return type.length > 0;
        } else if (Array.isArray(value)) {
          return value.length > 0;
        }
        return true;
      } else if (type === 'boolean') {
        return true;
      }
      return false;
    },
    showGroup(key) {
      if (this.allowEditable) {
        return true;
      }

      // get value and check if value empty
      const value = this.getProfileValue(key);
      return !types.empty(value);
    },

    hasGroups(keys) {
      if (this.allowEditable) {
        return true;
      }

      return keys.map(key => this.showGroup(key)).filter(v => v).length > 0;
    },

    getProfileValue(key) {
      return get(this, `profile.${key}`);
    },
    /**
     * resolve profile value and get the option text
     **/
    resolveProfileOptionValue(key, translationOptionKey = 'generic.unknown-true-false.options') {
      const value = this.getProfileValue(key);
      const fieldOptions = this.$t(translationOptionKey);
      return resolveOption(value, asOptions(fieldOptions), { text: '-' }).text;
    },
    /**
     * print section
     **/
    onPrint(sections) {
      const sectionNames = sections.map(sections => sections.name);
      this.printDetails = sectionNames.includes(SECTION_DETAIL);
      this.printContact = sectionNames.includes(SECTION_CONTACT);
      const printDocuments = sectionNames.includes(SECTION_DOCUMENTS);
      const { documents } = this.profile.education;

      this.$nextTick(async () => {
        // need to wait after dialog animation and remove other boostrap class flags
        await delay(100);

        window.print();

        // open documents
        if (printDocuments) {
          documents.forEach(document => {
            const { name, file } = document;
            const { id } = file;
            const uri = this.$api.document.downloadUri(id, name);
            const url = new URL(uri, location.origin); // not ssr save
            window.open(url, id);
          });
        }
      });
    },

    getModelValue(path) {
      return get(this.profile, path);
    },

    /**
     *  return value
     **/
    getValue(value, options = {}) {
      // autoselect by selector string
      if (typeof value === 'string') {
        value = get(this.profile, value);
      }

      if (!types.valid(value)) {
        return this.$t(`${prefix}.no-data-label`);
      } else if (types.boolean(value)) {
        return this.$t(`generic.` + (value ? 'true' : 'false'));
      }

      return value;
    },

    /**
     * create a configuration map based on model and config arguments
     * @param model
     * @param prefix
     * @param props
     * @returns {*}
     */
    getValueSet(model, prefix, props) {
      // catch invalid
      if (!model) {
        return [];
      }

      return props.map(prop => {
        let labelKey;
        let value;
        let label;
        let config = {};

        try {
          if (typeof prop === 'string') {
            labelKey = prop.toLowerCase();
            value = model[prop];
          } else {
            // transformation
            const propertyName = prop.selector || '';
            labelKey = propertyName.toLowerCase();
            value = model[propertyName];
            config = prop;
          }

          // custom label key
          if (config.labelKey) {
            label = config.labelKey;
          } else {
            label = `${this.prefix}.fields.${prefix}.${labelKey}`;
          }

          return {
            ...config,
            label,
            value,
          };
        } catch (e) {
          this.$logger.error(e.message);
        }

        return {};
      });
    },
  },
};
</script>

<style lang="scss">
@import '../../../component';

// variables
$border: 1px solid black;
$page-height: 29.7cm;
$page-width: 21cm;
$page-padding: 20pt;

// utils
@mixin viewport-property($name, $screen, $print) {
  @if $screen {
    #{$name}: $screen;
  }

  @media print {
    #{$name}: $print;
  }
}

@mixin font-size($screen, $print) {
  @include viewport-property(font-size, $screen, $print);
}

// definitions general

.vw-profile-detail {
  margin: auto;

  .o-profile-value-study {
    .study-label {
      display: block;
    }
  }

  .persona-split-container {
    display: block;
    overflow: hidden;

    > .persona-image {
      float: left;
      width: 30%;
    }
    > .persona-details {
      float: right;
      width: calc(70% - #{$space-default});
    }
  }

  .print-page {
    @include font-size(10pt, 12pt);
    text-align: left;
    background-color: white;

    .h6 {
      @include font-size(10pt, 12pt);
    }

    hr {
      clear: both;
      border-top: $border;
      @include space-vertical($space-default, margin);
    }

    .o-value {
      margin-bottom: $space-small;
    }

    .o-model-career-professional {
      margin-bottom: $space-default;
    }

    .page-headline,
    .page-subline,
    .o-profile-header .headline {
      clear: both;
      @include font-size(16px, 15pt);
    }

    .page-headline {
      font-weight: bold;
      display: block;
      border-bottom: $border;
      padding-bottom: $space-small;
      margin-bottom: $space-default;
    }

    .page-subline,
    .o-profile-header .headline {
      font-weight: bold;
      text-decoration: underline;
    }

    .page-subline,
    .o-profile-header {
      margin: {
        bottom: $space-small;
        top: $space-medium;
      }

      &.as-top-aligned {
        margin-top: 0;
      }
    }
  }

  .document {
    display: block;

    &-label {
      display: block;
    }

    @media screen {
      display: inline-block;
      text-align: center;
      margin: {
        right: $space-default;
        bottom: $space-default;
      }
    }
  }

  @media screen {
    .o-profile-header.positionable-top-right-corner {
      margin-top: 0;
    }

    .loader {
      @include position($top: 50%, $left: 50%);
      transform: translate(-50%, -50%);
    }

    .content-container {
      margin: $space-default auto;
      @include space-horizontal($space-default);
    }

    .content-container,
    .o-profile-toolbar-preview > .container {
      width: $page-width;
    }

    &.as-page-layout {
      .print-page {
        margin-bottom: $space-default;
        padding: $page-padding;
        min-height: $page-height;
        border: 1px solid rgba(51, 51, 51, 0.12);
        box-shadow: 4px 2px 4px 0 rgba(0, 0, 0, 0.45);
      }
    }

    &.as-combine-layout {
      .print-page {
        width: auto;
        padding: $page-padding;

        &:not(:last-child) {
          padding-bottom: 1px;
        }
      }

      .print-document {
        border: 1px solid rgba(51, 51, 51, 0.12);
        box-shadow: 4px 2px 4px 0 rgba(0, 0, 0, 0.45);
      }
    }
  }

  .value-input {
    padding-right: $space-default;
  }
}

$page-print-margin: 20pt;

@page {
  size: A4;
  margin: $page-print-margin;
}

@media print {
  html,
  body {
    background-color: white;
  }

  .print-section {
    page-break-inside: avoid;
  }

  .content-container {
    max-width: 90%;
  }
  
  .print-section > .page-headline,
  .o-profile-header {
      margin-top: 60px !important;
    }

  .vw-profile-detail {
    &.as-page-layout {
      page-break-after: always;

      .print-page {
        min-height: $page-height;

        hr {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }

    &.as-combine-layout {
      padding: $page-print-margin;
    }
  }
}
</style>
