<template>
  <ODialog
    modal-class="o-dialog-print for-screen-only"
    hide-close-button
    :headline="headline"
    :success-button-label="$t(`${prefix}.submit-button`)"
    v-model="isDisplayed"
    @success="onPrint"
  >
    <p v-if="description">{{ description }}</p>
    <div v-for="(item, key) in models" :key="key">
      <BFormCheckbox v-model="item.checked">
        {{ item.text }}
      </BFormCheckbox>
    </div>
  </ODialog>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue';
import ODialog from '../dialog';

const prefix = 'o.dialog-print';

export default {
  name: 'o-dialog-print',
  components: { ODialog, BFormCheckbox },
  model: {
    prop: 'show',
    event: 'change',
  },
  data() {
    return {
      models: [],
    };
  },
  props: {
    headline: String,
    description: String,
    show: Boolean,
    options: {
      type: Array,
    },
  },
  computed: {
    prefix: () => prefix,
    isDisplayed: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit('change', newValue);
      },
    },
  },
  created() {
    this.models = this.options.map(item => {
      if (typeof item === 'object') {
        return {
          checked: true,
          ...item,
        };
      }

      return {
        text: item,
        checked: true,
      };
    });
  },
  methods: {
    /**
     * print or dispatch list of selected items
     */
    onPrint() {
      // filter only all active selections
      const sections = this.models.filter(item => item.checked);

      // hide dialog
      this.isDisplayed = false;

      // push print event after updates
      this.$nextTick(() => {
        this.$emit('print', sections);
      });
    },
  },
};
</script>
