<template>
  <div class="o-profile-display-workplace">
    <template v-if="locationType">
      <div class="font-weight">
        {{ $t(`${prefix}.distance.${locationType}`, { distance }) }}
      </div>
      <OList v-if="hasLocations" :items="locationLabels" />
    </template>
    <template v-else>
      {{ $t('generic.not-set') }}
    </template>
  </div>
</template>
<script>
import { WORKSPACE_LOCATIONS } from 'library/src/models/workplace-location.enum';
import OValue from '../value';
import OList from '../list';

const prefix = 'o.model-workplace';

export default {
  name: 'o-model-workplace',
  components: { OList, OValue },
  props: {
    locationType: String,
    distance: Number,
    locations: Array,
  },
  computed: {
    prefix: () => prefix,
    hasLocations() {
      return this.locationType === WORKSPACE_LOCATIONS.LOCATIONS;
    },
    locationLabels() {
      const { locations } = this;

      if (Array.isArray(locations)) {
        return locations.map(location => location.label);
      }

      return '';
    },
  },
};
</script>
