<template>
  <div :class="className">
    <div v-if="isLoading" class="loader">
      <BSpinner large />
    </div>
    <BAlert v-else-if="errorCode" variant="warning" show>
      {{ $t(`${prefix}.errors.${errorCode}`) }}
    </BAlert>
    <template v-else>
      <OProfileToolbarPreview
        :no-back-button="!allowNavigation"
        :no-overview-button="!allowNavigation"
        sticky
        :printable-sections="printableSections"
        @print="onPrint" />

      <div class="print-container content-container">
        <CProfileDetail
          :profile="profile"
          :layout="layout"
          :allowEditable="allowEditable"
          :allowForbittenCompanies="allowForbittenCompanies"
          />
      </div>
    </template>
  </div>
</template>

<script>
import { asOptions } from 'library/src/utilities/as-options';
import get from 'lodash.get';
import ACL from 'library/src/models/acl.enum';
import { delay } from 'library/src/utilities/delay';

import { BSpinner, BAlert, BButton } from 'bootstrap-vue';
import SOIcon from 'library/components/src/components/so/icon';
import OValue from '../../o/value';
import OValues from '../../o/values';
import OList from '../../o/list';
import OProfileToolbarPreview from '../../o/profile/toolbar/preview';
import CProfileDetail from '../../c/profile/detail';
import ODownload from '../../o/download';
import OThumbnail from '../../o/thumbnail';
import OProfileHeader from '../../o/profile/header';
import OSticky from '../../o/sticky';
import SCEach from 'library/components/src/components/sc/each';

const prefix = 'vw.profile-detail';

const SECTION_DETAIL = 'detail';
const SECTION_CONTACT = 'contact';
const SECTION_DOCUMENTS = 'documents';
const DEFAULT_LAYOUT = 'combine';

export default {
  components: {
    CProfileDetail,
    BButton,
    SOIcon,
    BSpinner,
    BAlert,
    SCEach,
    OProfileHeader,
    OThumbnail,
    ODownload,
    OProfileToolbarPreview,
    OList,
    OValue,
    OValues,
    OSticky,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    token: String,
  },
  data() {
    return {
      fieldPath: 'profile-detail.fields', // translation path
      isLoading: true,
      layout: DEFAULT_LAYOUT,

      printContact: true,
      printDetails: true,

      profile: {
        rights: {},
        assessment: {},
        workspace: {},
        education: {},
        career: {},
      },
      errorCode: null,
    };
  },
  computed: {
    prefix: () => prefix,

    allowNavigation() {
      return !this.token;
    },

    allowEditable() {
      return !this.token && this.profile.rights.allowChange;
    },
    allowForbittenCompanies() {
      return this.$store.getters['auth/hasRight'](ACL.PROFILE_CONVEYING);
    },

    // definition of printable sections for print dialog
    printableSections() {
      return [SECTION_DETAIL, SECTION_CONTACT, SECTION_DOCUMENTS];
    },

    className() {
      const { layout } = this;

      return [
        'vw-profile-detail',
        `as-${layout}-layout`,
        {
          'is-loading': this.isLoading,
        },
      ];
    },
  },

  // hooks
  async created() {
    const { id } = this;

    this.$logger.info('Loading profile', id);

    // authentification by access token
    if (this.token) {
      try {
      await this.$store.dispatch('auth/accessToken', { token: this.token });
      } catch (e) {
        this.$logger.error('Invalid access token', e.message);
      }
    }

    this.$api.profile
      .getById(id)
      .then(profile => {
        Object.assign(this.profile, profile);
      })
      .catch(error => {
        this.errorCode = error.request.status;
      })
      .finally(() => (this.isLoading = false));
  },

  // methods
  methods: {
    isValid(value) {
      const type = typeof value;

      if (value) {
        if (type === 'string') {
          return type.length > 0;
        } else if (Array.isArray(value)) {
          return value.length > 0;
        }
        return true;
      } else if (type === 'boolean') {
        return true;
      }
      return false;
    },
    showGroup(key) {
      if (this.allowEditable) {
        return true;
      }

      // get value and check if value empty
      const value = this.getProfileValue(key);
      return !types.empty(value);
    },

    hasGroups(keys) {
      if (this.allowEditable) {
        return true;
      }

      return keys.map(key => this.showGroup(key)).filter(v => v).length > 0;
    },

    getProfileValue(key) {
      return get(this, `profile.${key}`);
    },
    /**
     * resolve profile value and get the option text
     **/
    resolveProfileOptionValue(key, translationOptionKey = 'generic.unknown-true-false.options') {
      const value = this.getProfileValue(key);
      const fieldOptions = this.$t(translationOptionKey);
      return resolveOption(value, asOptions(fieldOptions), { text: '-' }).text;
    },
    /**
     * print section
     **/
    onPrint(sections) {
      const sectionNames = sections.map(sections => sections.name);
      this.printDetails = sectionNames.includes(SECTION_DETAIL);
      this.printContact = sectionNames.includes(SECTION_CONTACT);
      const printDocuments = sectionNames.includes(SECTION_DOCUMENTS);
      const { documents } = this.profile.education;

      this.$nextTick(async () => {
        // need to wait after dialog animation and remove other boostrap class flags
        await delay(100);

        window.print();

        // open documents
        if (printDocuments) {
          documents.forEach(document => {
            const { name, file } = document;
            const { id } = file;
            const uri = this.$api.document.downloadUri(id, name);
            const url = new URL(uri, location.origin); // not ssr save
            window.open(url, id);
          });
        }
      });
    },

    getModelValue(path) {
      return get(this.profile, path);
    },

    /**
     *  return value
     **/
    getValue(value, options = {}) {
      // autoselect by selector string
      if (typeof value === 'string') {
        value = get(this.profile, value);
      }

      if (!types.valid(value)) {
        return this.$t(`${prefix}.no-data-label`);
      } else if (types.boolean(value)) {
        return this.$t(`generic.` + (value ? 'true' : 'false'));
      }

      return value;
    },

    /**
     * create a configuration map based on model and config arguments
     * @param model
     * @param prefix
     * @param props
     * @returns {*}
     */
    getValueSet(model, prefix, props) {
      // catch invalid
      if (!model) {
        return [];
      }

      return props.map(prop => {
        let labelKey;
        let value;
        let label;
        let config = {};

        try {
          if (typeof prop === 'string') {
            labelKey = prop.toLowerCase();
            value = model[prop];
          } else {
            // transformation
            const propertyName = prop.selector || '';
            labelKey = propertyName.toLowerCase();
            value = model[propertyName];
            config = prop;
          }

          // custom label key
          if (config.labelKey) {
            label = config.labelKey;
          } else {
            label = `${this.prefix}.fields.${prefix}.${labelKey}`;
          }

          return {
            ...config,
            label,
            value,
          };
        } catch (e) {
          this.$logger.error(e.message);
        }

        return {};
      });
    },
  },
};
</script>

<style lang="scss">
@import '../../../component';

// variables
$border: 1px solid black;
$page-height: 29.7cm;
$page-width: 21cm;
$page-padding: 20pt;

// utils
@mixin viewport-property($name, $screen, $print) {
  @if $screen {
    #{$name}: $screen;
  }

  @media print {
    #{$name}: $print;
  }
}

@mixin font-size($screen, $print) {
  @include viewport-property(font-size, $screen, $print);
}

// definitions general

.vw-profile-detail {
  margin: auto;

  .o-profile-value-study {
    .study-label {
      display: block;
    }
  }

  .persona-split-container {
    display: block;
    overflow: hidden;

    > .persona-image {
      float: left;
      width: 30%;
    }
    > .persona-details {
      float: right;
      width: calc(70% - #{$space-default});
    }
  }

  .print-page {
    @include font-size(10pt, 12pt);
    text-align: left;
    background-color: white;

    .h6 {
      @include font-size(10pt, 12pt);
    }

    hr {
      clear: both;
      border-top: $border;
      @include space-vertical($space-default, margin);
    }

    .o-value {
      margin-bottom: $space-small;
    }

    .o-model-career-professional {
      margin-bottom: $space-default;
    }

    .page-headline,
    .page-subline,
    .o-profile-header .headline {
      clear: both;
      @include font-size(16px, 15pt);
    }

    .page-headline {
      font-weight: bold;
      display: block;
      border-bottom: $border;
      padding-bottom: $space-small;
      margin-bottom: $space-default;
    }

    .page-subline,
    .o-profile-header .headline {
      font-weight: bold;
      text-decoration: underline;
    }

    .page-subline,
    .o-profile-header {
      margin: {
        bottom: $space-small;
        top: $space-medium;
      }

      &.as-top-aligned {
        margin-top: 0;
      }
    }
  }

  .document {
    display: block;

    &-label {
      display: block;
    }

    @media screen {
      display: inline-block;
      text-align: center;
      margin: {
        right: $space-default;
        bottom: $space-default;
      }
    }
  }

  @media screen {
    .o-profile-header.positionable-top-right-corner {
      margin-top: 0;
    }

    .loader {
      @include position($top: 50%, $left: 50%);
      transform: translate(-50%, -50%);
    }

    .content-container {
      margin: $space-default auto;
      @include space-horizontal($space-default);
    }

    .content-container,
    .o-profile-toolbar-preview > .container {
      width: $page-width;
    }

    &.as-page-layout {
      .print-page {
        margin-bottom: $space-default;
        padding: $page-padding;
        min-height: $page-height;
        border: 1px solid rgba(51, 51, 51, 0.12);
        box-shadow: 4px 2px 4px 0 rgba(0, 0, 0, 0.45);
      }
    }

    &.as-combine-layout {
      .print-page {
        width: auto;
        padding: $page-padding;
      }

      .print-document {
        border: 1px solid rgba(51, 51, 51, 0.12);
        box-shadow: 4px 2px 4px 0 rgba(0, 0, 0, 0.45);
      }
    }
  }

  .value-input {
    padding-right: $space-default;
  }
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  html,
  body {
    background-color: white;
    padding-left: 28pt;
  }

  .print-section {
    page-break-inside: avoid;
    padding-top: $space-default;
    padding-bottom: $space-default;
  }

  .content-container {
    max-width: 90%;
  }

  .vw-profile-detail {
    .print-page {
      .o-profile-header {
        margin-top: 0;
        padding-top: $space-default;
      }
    }

    &.as-page-layout {
      page-break-after: always;

      .print-page {
        padding: 40pt;
        min-height: $page-height;

        hr {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }

    &.as-combine-layout {
      padding: 40pt;
    }
  }
}
</style>
