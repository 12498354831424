<template>
  <a :href="downloadUrl" download target="download">
    <span v-if="label" class="label">{{ label }}</span>
    <span v-else-if="id" class="label">{{ id }}</span>
    <span :class="urlClassName"> [{{ downloadUrl }}]</span>
  </a>
</template>
<script>
export default {
  name: 'o-download',
  props: {
    href: String,
    id: String,
    fileType: Object,
    label: String,
    showUrl: Boolean,
  },
  computed: {
    className() {
      return 'o-download';
    },
    urlClassName() {
      // show only for print of not flag enable
      if (!this.showUrl) {
        return 'for-print-only';
      }
    },
    downloadUrl() {
      const { id, href, label } = this;
      let url = href;
      if (id) {
        url = this.$api.document.downloadUri(id, label);
      }
      return new URL(url, location.origin); // not ssr save
    },
  },
};
</script>
