<template>
  <OValue class="o-model-career-education" variant="column">
    <ODate slot="label" :date="date" show-not-set />
    {{ description }}
  </OValue>
</template>

<script>
import OValue from '../../value';
import ODate from 'library/components/src/components/so/date';

/**
 * @info can be used with education and formations data structures
 */
export default {
  name: 'o-model-career-education',
  components: { ODate, OValue },
  props: ['date', 'description'],
};
</script>
