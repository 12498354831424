<template>
  <OValue class="o-model-career-school" variant="column">
    <ODate slot="label" :date="date" show-not-set />
    <div v-if="name" class="h6 font-weight-bold">{{ name }}</div>
    <div :class="{ 'font-weight-bold': !name }">
      {{ typeOfDegreeLabel }}
    </div>
  </OValue>
</template>

<script>
import OValue from '../../value';
import ODate from 'library/components/src/components/so/date';

const prefix = 'o.model-career-school';

export default {
  name: 'o-model-career-school',
  components: { ODate, OValue },
  props: {
    name: String,
    completed: Boolean,
    date: Object,
    typeOfDegree: String,
  },
  computed: {
    prefix: () => prefix,
    typeOfDegreeLabel() {
      const { typeOfDegree, completed } = this;

      if (completed) {
        return typeOfDegree ? this.$t(`generic.school-degree.enum.${typeOfDegree}`) : this.$t('generic.not-set');
      }

      return this.$t(`${prefix}.not-completed-label`);
    },
  },
};
</script>
