<template>
  <nav :class="className" role="navigation">
    <BContainer class="text-right">
      <div class="button-container">
        <BButton
          v-if="!noBackButton"
          variant="dark"
          data-identity="profile-toolbar-preview-back"
          :title="$t(`${prefix}.back-button-title`)"
          @click="onBack"
        >
          <SOIcon name="arrow-left" size="sm" />
        </BButton>
        <BButton
          v-if="!noOverviewButton"
          variant="light"
          data-identity="profile-toolbar-preview-reservation"
          :title="$t(`${prefix}.reservation-button-title`)"
          :to="{ name: 'direct-pv.reserved' }"
        >
          <SOIcon name="user-cog" size="sm" />
        </BButton>
        <BButton
          v-if="!noOverviewButton && isOwnerAllowed"
          variant="light"
          data-identity="profile-toolbar-preview-owner"
          :title="$t(`${prefix}.owner-profiles-button-title`)"
          :to="dashboardLink"
        >
          <SOIcon name="users" size="sm" />
        </BButton>
        <BButton
          variant="light"
          data-identity="profile-toolbar-preview-print"
          :title="$t(`${prefix}.print-button-title`)"
          @click.prevent="showPrintDialog = true"
        >
          <SOIcon name="print" size="sm" />
        </BButton>
        <ODialogPrint
          v-bind="$t(`${prefix}.print-dialog`)"
          v-model="showPrintDialog"
          :options="printOptions"
          @print="$emit('print', $event)"
        />
      </div>
    </BContainer>
  </nav>
</template>

<script>
import { currentUserMixin } from 'library/components/src/plugins/auth/mixin/current-user.mixin';
import { PROFILE_CONVEYING } from 'library/src/models/acl.enum';
import { ownerRouteId } from '@/src/sitemap';
import { BButtonGroup, BButton, BContainer } from 'bootstrap-vue';
import SOIcon from 'library/components/src/components/so/icon';
import ODialogPrint from '../../dialog/print';

const prefix = 'o.profile-toolbar-preview';

export default {
  name: 'o-profile-toolbar-preview',
  mixins: [currentUserMixin],
  components: {
    BButtonGroup,
    BButton,
    BContainer,
    ODialogPrint,
    SOIcon,
  },
  model: {
    prop: 'currentView',
    event: 'change',
  },
  data() {
    return {
      showPrintDialog: false,
      currentLayoutIndex: 0,
    };
  },
  props: {
    currentView: String,
    selectedView: String,
    printableSections: {
      type: Array,
      require: true,
    },
    noBackButton: Boolean,
    noOverviewButton: Boolean,
    sticky: Boolean,
  },
  computed: {
    prefix: () => prefix,
    view: {
      get() {
        return this.currentView;
      },
      set(viewName) {
        this.$emit('change', viewName);
      },
    },
    className() {
      return [
        'o-profile-toolbar-preview',
        'for-screen-only content-component',
        {
          'sticky-top': this.sticky,
        },
      ];
    },
    dashboardLink() {
      return { name: ownerRouteId };
    },
    printOptions() {
      return this.printableSections.map(name => {
        return {
          text: this.$t(`${prefix}.print-sections.${name}`),
          name,
        };
      });
    },
    isOwnerAllowed() {
      return this.isAllowed(PROFILE_CONVEYING);
    },
  },
  methods: {
    onBack() {
      this.$router.back();
    },
  },
};
</script>

<style>
.o-profile-toolbar-preview {
  background-color: #f8f9fa;
}
</style>