<template>
  <OValue class="o-model-career-study" variant="column">
    <ODate slot="label" :date="date" show-not-set />
    <div class="h6 font-weight-bold">{{ name }}</div>
    <OValue v-if="location" :label="`${prefix}.location-label`" variant="line">
      {{ location }}
    </OValue>
    <OValue v-if="focus" :label="`${prefix}.focus-label`" variant="line">
      {{ focus }}
    </OValue>
    <div class="completed-label">
      {{ completeLabel }}
    </div>
  </OValue>
</template>

<script>
import OValue from '../../value';
import ODate from 'library/components/src/components/so/date';

const prefix = 'o.model-career-study';

export default {
  name: 'o-model-career-study',
  components: { ODate, OValue },
  props: {
    date: Object,
    name: String,
    location: String,
    focus: String,
    completed: Boolean,
    typeOfDegree: String,
  },
  computed: {
    prefix: () => prefix,
    completeLabel() {
      return this.completed ? this.$t(`${prefix}.completed-label`) : this.$t(`${prefix}.not-completed-label`);
    },
  },
};
</script>
