<template>
  <OValue :class="className" variant="column">
    <ODate slot="label" :date="date" show-not-set />
    <div class="h6 font-weight-bold">{{ title }}</div>
    <OValue v-if="business" :label="`${prefix}.business-label`" variant="line">
      {{ $t(`generic.business.enum.${business}`) }}
    </OValue>
    <OModelCareerJob
      v-for="(job, index) in jobs"
      class="career-job-entry"
      :class="hideJobDate ? 'has-no-date' : 'has-date'"
      :key="`job-${index}`"
      :hide-date="hideJobDate"
      v-bind="job"
    />
    <OValue v-if="eventOfDefault" :label="`generic.event-of-default.label`" variant="line">
      {{ eventOfDefaultDescriptionLabel }}
    </OValue>
  </OValue>
</template>

<script>
import { isLessThan } from 'library/src/utilities/types';
import OValue from '../../value';
import ODate from 'library/components/src/components/so/date';
import OModelCareerJob from './job';

const prefix = 'o.model-career-professional';

export default {
  name: 'o-model-career-professional',
  components: { OModelCareerJob, ODate, OValue },
  props: {
    date: Object,
    title: String,
    business: String,
    eventOfDefault: String,
    eventOfDefaultDescription: String,
    jobs: Array,
  },
  computed: {
    prefix: () => prefix,
    className() {
      return [
        'o-model-career-professional',
        {
          'has-hidden-job-date': this.hideJobDate,
        },
      ];
    },
    hideJobDate() {
      return isLessThan(this.jobs, 2);
    },
    eventOfDefaultDescriptionLabel() {
      const tPrefix = 'generic.event-of-default';
      const { eventOfDefaultDescription, eventOfDefault } = this;

      if (eventOfDefault === 'others') {
        if (eventOfDefaultDescription) {
          return eventOfDefaultDescription;
        }
        return this.$t(`${tPrefix}.without-description-label`);
      }

      return this.$t(`${tPrefix}.enum.${eventOfDefault}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../component';

.career-job-entry.has-date {
  margin-top: $space-default;
}
</style>
